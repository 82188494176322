$mobile-size: 950px;

$name-colour: #013A20;
$nav-colour: #395d39;
$nav-colour-secondary: #013A20;

.pre-footer {
    margin: 40px auto 0;
}

.page-footer {
    margin: 20px auto 50px;;
}

.page-footer, .pre-footer {
    max-width: 1000px;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: rgba($nav-colour, 0.6);
    text-align: center;
}

.footer-button {
    text-align: center;
    padding: 10px 0;
    border-radius: 10px;
    background-color: rgba(#bbd3b7, 0.4);
    width: 150px;
    margin: 30px auto;
}

.socials {
    display: flex;
    flex-direction: row;
    gap: 50px;

    &> * {
        padding: 10px;
    }
}

@media screen and (max-width: $mobile-size) {

    .footer-button {
        width: 75%;
        background-color: rgba($nav-colour, 0.2);
    }

}