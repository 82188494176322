$mobile-size: 950px;

.work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: rgba(217, 217, 217, 0.7);
    margin: 0px 142px;
    padding-top: 25px;
    border-radius: 5px;

    .switch{
        margin: 10px;
    }
}

.slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}

.work-image {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    padding: 20px 20px;

    .gallery-image {
        width: 200px;
    }

    &.shows .gallery-image {
        width: 400px;
    }

    img {
        width: 100%;
        border: 2px solid #aaa;
    }
}

@media screen and (max-width: $mobile-size) {
    .work-image {
        gap: 5%;
        padding: 5%;
        .gallery-image {
            width: 42.5%;
        }
    }

    .work-image.shows  .gallery-image {
        width: 90%;
    }

    .work {
        background-color: unset;
        margin: 0px 10px;
    }
}

@media screen and (min-width: $mobile-size) {
    .work-image {
        width: 900px;
        margin: 0 auto;
    }
}
