$mobile-size: 950px;

.page-split {
    display: flex;
    flex-direction: row;
    img, div {
        width: 50%;
    }
}

.background-image {
    background-position: center !important;
    margin: 0 50px 50px !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.full-height {
    height: 60vh;
}

.split {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .gallery-image, .about, .image-loader {
        width: 46%;
        margin: 0 2% 50px;

        img {
            width: 100%;
        }
    }

    .about {
        padding-top: 40px;
    }
}

@media screen and (max-width: $mobile-size) {
    .background-image {
        margin: 0 10px 10px !important;
    }

    .split {
        flex-direction: column-reverse;
        justify-content: unset;
    
        .gallery-image, .about, .image-loader {
            width: unset;
            margin: 0 10px 10px;
        }
    }
}