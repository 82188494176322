$mobile-size: 950px;
$name-colour: #013A20;
$nav-colour: #304E30;
$nav-colour-secondary: #013A20;

body {
    background: rgb(255, 255, 255);
    color: $nav-colour;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

a {
    cursor: pointer;
}

.page {
    // max-width: 1000px;
    margin: 40px auto 0;
    padding: 5px;
}

.min-height {
    min-height: 65vh;
}

p {
    margin-bottom: 10px;
}

.svg-size {
    height: 30px;
    width: 30px;
}

h1, h2 {
  letter-spacing: 10px;
  &::after{
      content: "\a";
      white-space: pre;
  }
}

p::after{
    content: "\a";
    white-space: pre;
}

.loader {
    width: 100px;
    height: 100px;
    display: flex;
    margin: 100px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;

    &.theme-color::before {
        border: 5px solid rgba($nav-colour, 0.3);
    }
}
.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite ;
}

.back-img {
    height: 70vh;
    width: 75vw;
    margin: 0 auto;
    position: relative;
    text-align: center;

    .gallery-image, .gallery-image img {
        height: 100%;
        width: 100%;
    }

    .gallery-image img {
        object-fit: cover;
        opacity: 0.5;
    }

    .contact {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.home .back-img {
    width: 35vw;
    height: 40vw;
    .gallery-image img {
        object-fit: cover;
        // opacity: 0.5;
    }

    .contact {
        width: 80%;
    }
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

@media screen and (max-width: $mobile-size) {
    .page {
        max-width: unset;
        margin: 0 auto;
    }

    .home .back-img {
        width: 80vw;
        height: 100vw;
        .gallery-image img {
            object-fit: cover;
        }
    
        .contact {
            width: 80%;
        }
    }
    
    .back-img {
        height: 50vh;
        width: 95vw;
    }
}