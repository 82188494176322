$mobile-size: 950px;

$name-colour: #013A20;
$nav-colour: #395d39;
$nav-colour-secondary: #013A20;

.link-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: rgba(#e5e7eb, 1);
    color: black;
    gap: 40px;
    padding: 60px 20px;
    height: 500px;
  
    h1 {
        text-align: center;
    }
  
    div {
      max-width: 700px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    button {
        padding: 10px;
        width: 200px;
        margin-top: 10px;
        text-align: center;
        border-radius: 5px;
        
        pointer-events: auto;
        cursor: pointer;
        background-color: rgba(#bbd3b7, 1);
        // color: #ffffff;
        color: #000;
        padding: 10px 10px;
        text-align: center;
    }
  
}

.contact button {
    width: 175px;

    &.email-copy {
        background-color: rgba(#96d28c, 1);
        content: "Copied!";
    }
}

.email {
    font-size: 20pt;
    background-color: white;
    color: rgba(48, 48, 48, 1);
    padding: 10px 20px;
    border-radius: 5px;
    // border: 1px solid rgba(48, 48, 48, 0.5);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

@media screen and (max-width: $mobile-size) {
    .back-img {
        width: unset;
    }
}