$mobile-size: 950px;

$name-colour: #013A20;
$nav-colour: #395d39;
$nav-colour-secondary: #013A20;

.page-header {
    max-width: 1000px;
    margin: 40px auto;
    padding: 5px;
}

.menu-button {
    display: none;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;

    .website-title {
        display: flex;
        flex-direction: column;
        color: $name-colour;
        font-size: 50px;
        h2 {
            color: rgba($nav-colour, 0.6);
            font-size: 18px;
        }
    }

    a {
        padding: 10px;
    }

    nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        white-space: nowrap;
        color: rgba($nav-colour, 0.6);

        a {
            font-size: 23px;

            &:hover {
                color: rgba($nav-colour-secondary, 0.8);
                font-weight: 500;
            }
        }
    }
}

@media screen and (max-width: $mobile-size) {

    .page-header {
        max-width: unset;
        align-items: center;
        flex-direction: column-reverse;
        margin: -165px auto 40px;

        button {
            width: 90%;
            padding: 10px 0;
            border-radius: 2px;
            background-color: rgba($nav-colour, 0.2);
            text-align: center;
            
        }

        .menu-button {
            display: block;
        }

        nav {
            margin-bottom: 10px;
            width: 90%;
            a {
                justify-content: center;
            }
        }
    }

    .menu {
        transform: translate(0, 0);
        transition: transform 0.5s ease-in-out; // for the transition
        
        &.open {
            transform: translate(0, 165px);
            transition: transform 0.5s ease-in-out; // for the transition
        }
    }


    header .website-title {
        margin-top: 25px; // for the transition
        font-size: 30px;
        align-items: center;
        justify-content: center;

        h1 {
            text-align: center;
        }

        h2 {
            text-align: center;
            letter-spacing: 5px;
        }
    }

    header nav a {
        font-size: 18px;
    }

    header {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        nav {
            flex-direction: column;
            width: 100%;
            text-align: center;
        }
    }
}