$nav-colour: #395d39;
$green: #bbd3b7;
$green-solid: #C4D9C1;

.switch {
    --circle-dim: 1.4em;
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 8em;
    height: 2.6em;

    &:focus, :visited, :active{
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($nav-colour, 0.1);
    transition: 0.7s;
    border-radius: 30px;

    &:focus, :visited, :active{
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
  }
  
  .slider-card {
    position: absolute;
    content: "";
    height: 35px;
    width: 70px;
    // height: var(--circle-dim);
    // width: var(--circle-dim);
    border-radius: 30px;
    left: 0.3em;
    bottom: 0.3em;
    transition: 0.7s;
    pointer-events: none;

    &:focus, :visited, :active{
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
  }
  
  .slider-card-face {
    position: absolute;
    inset: 0;
    backface-visibility: hidden;
    perspective: 1000px;
    border-radius: 15px;
    // border-radius: 50%;
    transition: 0.7s transform;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-card-front {
    // background-color: rgba($green, 0.8);
    background-color: rgba($green-solid, 1);
  }
  
  .slider-card-back {
    // background-color: rgba($green, 0.8);
    background-color: rgba($green-solid, 1);
    transform: rotateY(180deg);
  }
  
  input:checked ~ .slider-card .slider-card-back {
    transform: rotateY(0);
  }
  
  input:checked ~ .slider-card .slider-card-front {
    transform: rotateY(-180deg);
  }
  
  input:checked ~ .slider-card {
    transform: translateX(3.3em);
  }
  
  input:checked ~ .slider {
    background-color: rgba($nav-colour, 0.1);
  }