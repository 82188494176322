$mobile-size: 950px;

.gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;

    &.waiting {
        align-items: center;
        height: 60vh;
    }

    .gallery-column {
        display: flex;
        flex-direction: column;
        width: 32vw;
        gap: 10px;

        .gallery-image {
            width: unset;
            opacity: 1;

            img {
                max-height: 100%;
                width: 100%;
                object-fit: cover;
                vertical-align: middle;
            }
        }
    }

    .gallery-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        .gallery-image {
            height: 32vw;
            max-width: 32.8%;
            flex-grow: 1;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                vertical-align: middle;
            }
        }
        &::after {
            content: "";
            flex-grow: 999;
        }
    }
}

.gallery-image {
    opacity: 1;
    transform: translate(0);
    transition: transform 5s ease-in-out;
    transition: opacity 1s ease-in-out;

    &.wait-image {
        position: absolute;
        width: 0px;
        height: 0px;
        opacity: 0; 
        transform: translate(-9999px);
        transition: transform 5s ease-in-out;
        transition: opacity 1s ease-in-out;
    }

    img {
        border-radius: 3px;
    }
}

.image-loader {
    background-color: rgba(#013A20, 0.1);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
}

@media screen and (max-width: 1280px) {
    .gallery .gallery-column {
        width: 35vw;
    }
}



@media screen and (max-width: $mobile-size) {
    .gallery .gallery-column {
        width: 90vw;
    }

    .gallery .gallery-row .gallery-image {
        height: 70vw;
    }

    .gallery .gallery-row .gallery-image {
        max-width: unset; // fix for safari
    }
}