$mobile-size: 950px;

.home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1, h2 {
    text-align: center;
  }

  img {
    object-fit: cover;
    width: 100%;
    opacity: 0.4;
    -webkit-filter: grayscale(60%);
    filter: grayscale(60%);
  }
}

@media screen and (max-width: $mobile-size) {
}