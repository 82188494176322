$mobile-size: 950px;
$nav-colour: #395d39;

.about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: justify;
    
    div {
        display: flex;
        flex-direction: column;
    }

    .about-button {
        text-align: center;
        padding: 10px 0;
        border-radius: 2px;
        background-color: rgba(#bbd3b7, 1);
        width: 150px;
        margin: 10px auto;
    }
}

@media screen and (min-width: $mobile-size) {

    .about-image {
        width: 900px;
        margin: 0 auto 20px;
        height: 600px;
        
        img {
            width: 100%;
            position: absolute;
            opacity: 0.7;
        }
    
        .about {
            position: relative;
            height: 600px;
            bottom: 0px;
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 50% 50%;
    
            div {
                padding: 40px 90px 40px 20px;
                justify-content: space-between;
    
                p {
                    // padding: 0 60px;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-size) {

    .about-image {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        gap: 30px;

        .about-button {
            width: 75%;
            background-color: rgba($nav-colour, 0.2);
        }
        
        img {
            width: 100%;
        }
    }

}